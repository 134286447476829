<template>
    <div class="center-main" style="padding: 0 10px 0 0">
        <div v-if="!errorShow" >
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper" style="margin-top:0px;">
                <global-page-back
                    detailPageTitle="商品限购设置"
                    @handlerGlobalType="handlerGlobalType"
                >
                </global-page-back>
                <div style="margin-top:10px;">
                    <expand-filter :formData="formData"  @clickBtn="clickBtn" class="content-wrapper"></expand-filter>
                </div>
                <table-data
                    v-loading="loadingTable"
                    id="table"
                    ref="table"
                    :config="table_config"
                    :tableData="table_data"
                    :backgroundHeader="backgroundHeader"
                    :headerColor="headerColor">
                    <template v-slot:jointData="jojjint">
                        <div v-if="jojjint.data.limitedPickingPeriod == '1'">{{'不限'}}</div>
                        <div v-else-if="jojjint.data.limitedPickingPeriod == '2'">{{'每月 '}}{{jojjint.data.limitedPickingNumber}}{{jojjint.data.unit}}</div>
                        <div v-else-if="jojjint.data.limitedPickingPeriod == '3'">{{'每年 '}}{{jojjint.data.limitedPickingNumber}}{{jojjint.data.unit}}</div>
                        <div v-else-if="jojjint.data.limitedPickingPeriod == '4'">{{'一次性 '}}{{jojjint.data.limitedPickingNumber}}{{jojjint.data.unit}}</div>
                    </template>
                    <template v-slot:operation="slotData">
                        <xk-button @click="goedit(slotData.data)" type="primary" class="buttoncs1" size="mini">编辑</xk-button>
                        <xk-button @click="godelete(slotData.data)" type="danger" class="buttoncs2" size="mini">删除</xk-button>
                    </template>
                </table-data>
                <pagination
                    :total="total"
                    :page.sync="listQuery.pageNum"
                    :limit.sync="listQuery.pageRow"
                    @pagination="getList"
                    />
                <dialog-wrapper :dialog-obj="dialogObj" @handleClose="handleClose">
                    <div style="margin:10px 20px;">
                        <el-form ref="rulesForm" :model="rulesForm" :rules="rules" label-width="110px" >
                            <el-form-item label="商品编号" prop="goodsNo">
                                <el-input v-model="rulesForm.goodsNo" maxlength="20" placeholder="请输入商品编号"></el-input>
                            </el-form-item>
                            <el-form-item label="商品名称" prop="goodsName">
                                <el-input v-model="rulesForm.goodsName" maxlength="20" placeholder="请输入商品名称"  />
                            </el-form-item>
                            <el-form-item label="商品单位" prop="unit">
                                <el-input v-model="rulesForm.unit" maxlength="3" placeholder="请输入商品单位"  />
                            </el-form-item>
                            <el-form-item label="商品应用性别" prop="sex">
                                <el-radio-group v-model="rulesForm.sex">
                                    <el-radio label="3">不限</el-radio>
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="每天限领个数" prop="limitedPickingNumberDay">
                                <el-input v-model="rulesForm.limitedPickingNumberDay" maxlength="3"  placeholder="请输入每天限领个数" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" />
                            </el-form-item>
                            <el-form-item label="最多领取个数" prop="limitedPickingPeriod">
                                 <div  style="display: flex;">
                                    <el-select v-model="rulesForm.limitedPickingPeriod" style="width:140px;" >
                                        <el-option label="不限" value="1"></el-option>
                                        <el-option label="每月" value="2"></el-option>
                                        <el-option   el-option label="每年" value="3"></el-option>
                                        <el-option label="一次性" value="4"></el-option>
                                    </el-select>
                                <el-input v-model="rulesForm.limitedPickingNumber" maxlength="5"  onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" style="width:auto;margin-left: 20px;" v-if="rulesForm.limitedPickingPeriod != '1'" placeholder="请输入最多领取个数"></el-input>
                                </div>

                                </el-form-item>

                        </el-form>
                    </div>
                        <div style="text-align: center">
                            <el-button @click="handleSubmit" type="primary">确 定</el-button>
                            <el-button @click="handleClose">取 消</el-button>
                        </div>
                    </dialog-wrapper>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>
<script>
import {
  // 业务组件
  TableData,
  DialogWrapper,
  Pagination,
  // 功能组件
  Error,
  Loading,
  debounce
} from 'common-local';
// Ajax 请求
import {
  SystemModel
} from '@/models/System.js';
import GlobalPageBack from '../../Sub/GlobalPageBack/index.vue'
import ExpandFilter from "../../Sub/ExpandFilter.vue";

export default {
    name: "List",
    components:{
        TableData,
        DialogWrapper,
        Pagination,
        ExpandFilter,
        GlobalPageBack,
        // 功能组件
        Error,
        Loading,
    },
    data(){
        return{
            loadingTable:false,
            listQuery:{
                pageNum: 1,
                pageRow: 20,
                schoolId:'',
                goodsNo:'',
                goodsName:'',

            },
             // 弹窗数据
             dialogObj: {
                title: '',
                dialogVisible: false,
                width: '500px'
            },
            rulesForm:{
                schoolId:'',
                goodsNo:'',
                goodsName:'',
                unit:'个',
                sex:'3',
                limitedPickingNumberDay:'',
                limitedPickingPeriod:'1',
                limitedPickingNumber:'',
                id:'',
            },
            rules:{
                goodsNo:[{required:true,message:'请输入商品编号',trigger:'blur'}],
                goodsName:[{required:true,message:'请输入商品名称',trigger:'blur'}],
                // limitedPickingNumberDay:[{required:true,message:'请输入每天限领个数',trigger:'blur'}],
            },
            // 头部筛选
            formData:{
                formInline:{
                    deptName:'',
                },
                data: [{
                    type: 'input',
                    label: '',
                    value: '',
                    placeholder: '商品编号',
                    key: 'goodsNo',
                    },
                    {
                    type: 'input',
                    label: '',
                    value: '',
                    placeholder: '商品名称',
                    key: 'goodsName',
                    }
                ],
                btnList: [{
                    type: "enquiry",
                    text: '查询',
                    fn: 'primary'
                },
                    {
                        type: "enquiry",
                        text: '重置',
                        fn: 'reset'
                    },
                    // {
                    //     type: "info",
                    //     text: '返回',
                    //     fn: 'goBack'
                    // },
                    {
                        type: "primary",
                        text: '添加',
                        fn: 'add'
                    }
                ],
            },
            // 表格配置
            table_config:{
                thead:[{
                    label:"商品编号",
                    prop:"goodsNo",
                    },
                    {
                    label:"商品名称",
                    prop:"goodsName",
                    type:'popoverText'
                    },
                    {
                    label:"商品单位",
                    prop:"unit",
                    },
                    {
                    label:"商品应用性别",
                    prop:"sex",
                    type: "function",
                    callBack(row) {
                        return {
                        3: "不限",
                        2: "女",
                        1: "男"
                        }[+row.sex];
                    },
                    },
                    {
                    label:"每天限购个数",
                    prop:"limitedPickingNumberDay",
                    },
                    {
                    label:"最多购买个数",
                    prop:"limitedPickingNumber",
                    type:'smj',
                    slotName:'jointData'
                    },
                    {
                    label:"操作",
                    type:'slot',
                    labelWidth: '350',
                    slotName: 'operation'
                    },
                ],
                check: false,
                rowkey: 'id',
                height: '',
                isExpandAll: true
            },
            // 表格数据
            table_data: [],
            // 功能组件
            errorShow: false,
            loadingShow: false,
            // 表头
            backgroundHeader: '#FAFBFC',
            // 表头字体
            headerColor: '#595959',
            total:0,
        }
    },
    computed:{

    },
    created(){
        this.init()
    },
    mounted() {
        console.log('出发了吗')
        this.$nextTick(() => {
            setTimeout(() => {
                if(document.getElementById("table")) {
                    this.table_config.height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                }
                window.onresize = debounce(() => {
                    if (!document.getElementById("table")) return;
                    const height = document.body.clientHeight - document.getElementById("table").offsetTop - 135;
                    this.table_config.height = height.toString();
                }, 200);

            }, 100);
        });
    },
    methods:{
        /**
         * 初始化
         * getList  初始化列表
         * clickBtn 查询/重置
         */
        init(){
            this.listQuery.schoolId = this.rulesForm.schoolId = this.$store.state.schoolId;
            this.getList()

        },
        getList(){
            const systemModel = new SystemModel();
            systemModel.getGoodsList(this.listQuery).then((res) =>{
                this.handleRes(res, () => {
                // this.table_data = res.data.data.list
                this.table_data = [
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    },
                    {
                        goodsNo: '1'
                    }
                ]
                this.total = res.data.data.totalCount
                })
            })
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case 'primary': //查询
                    this.formData.data.forEach(item => {
                        this.listQuery[item.key] = item.value
                    })

                    this.getList()
                    break;
                case 'reset': //重置
                    this.formData.data.forEach((item) => {
                        item.value = ''
                        this.listQuery[item.key] = ''
                    })
                    this.getList()
                    break;
                case 'goBack': //返回
                    this.$emit("changeComp","back")
                    break;
                case 'add': //新增
                    this.add()
                    break;
            }
        },

        /**
         * 操作按钮
         * add    添加
         * goedit   编辑
         * godelete     删除
         * handleSubmit     添加/编辑 确定按钮
         * handleClose      关闭弹框
         */
         add(){
            this.dialogObj.title = '添加商品限购规则'
            this.dialogObj.dialogVisible = true
        },
         goedit(data){
            this.rulesForm.goodsNo = data.goodsNo
            this.rulesForm.goodsName = data.goodsName
            this.rulesForm.unit = data.unit
            this.rulesForm.sex = data.sex
            this.rulesForm.limitedPickingNumberDay = data.limitedPickingNumberDay
            this.rulesForm.limitedPickingPeriod = data.limitedPickingPeriod
            this.rulesForm.limitedPickingNumber = data.limitedPickingNumber
            this.rulesForm.id = data.id
            this.dialogObj.title = '编辑商品限购规则'
            this.dialogObj.dialogVisible = true
         },
         handleSubmit(){
            this.$refs.rulesForm.validate((valid) =>{
                if (!valid) return false
                if(this.rulesForm.limitedPickingPeriod == '1'){
                    this.rulesForm.limitedPickingNumber = ''
                }
            const systemModel = new SystemModel();
            systemModel.saveGoods(this.rulesForm).then(res => {
                    this.handleRes(res,()=>{
                        console.log(res,'20202020202020');
                        if(this.dialogObj.title == '添加商品限购规则'){
                            this.$message.success("添加成功")
                        }else{
                            this.$message.success("修改成功")
                        }

                    this.getList()
                    this.handleClose()
                            })
                        })
            })

        },
        handleClose(){
            this.$refs.rulesForm.resetFields()
            this.dialogObj.dialogVisible = false

            this.rulesForm.goodsNo = ''
            this.rulesForm.goodsName = ''
            this.rulesForm.unit = '个'
            this.rulesForm.sex = '3'
            this.rulesForm.limitedPickingNumberDay =''
            this.rulesForm.limitedPickingNumber =''
            this.rulesForm.limitedPickingPeriod = '1'
            this.rulesForm.id = ''
            // this.$nextTick(() => {
            //     this.$refs.rulesForm.clearValidate()
            // })
        },
         godelete(data){
            this.$confirm('确定要删除该商品吗？','提示',{
              confirmButtonText:'确定',
              cancelButtonText:'取消',
              type: 'warning'
          }).then(()=>{
            const deleteList = [{id: data.id}]
            const systemModel = new SystemModel();
            systemModel.deleteGoods(deleteList).then(res => {
                if (res.data.code === '200') {
                    this.$message.success("删除成功！")
                      this.getList()
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
              })
          })
         },
         handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data.code === '400') {
                this.$message.error('网络异常，请稍后再试！')
            } else {
                this.$message.error(res.data.msg)
            }
            },
             // 表格高度
        // initTableHeight() {
        //     this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 72
        // },
        handlerGlobalType () {
            this.$emit('handlerGlobalType')
        }
    }
}
</script>
<style  lang="scss" scoped>
// .title{
//     width: 100%;
//     height: 20px;
//      background-color: #ffffff;
//      padding:20px;
//      font-size: 20px;
//      color: red;
//      font-weight: bold;
//      margin-bottom:2px;

// }
// .buttoncs1{
//     padding:8px;
//     color: #ffffff;
//     background-color:#3C7FFF;
// }
// .buttoncs2{
//     padding:8px;
//     color: #ffffff;
//     background-color:#f5b942;
// }
</style>
